<template>
  <div class="announcements-add add-template">
    <div class="g-background container bg-block">
      <header class="page-header container">
        <h1>{{ translations.tcAddEvent }}</h1>
      </header>
      <div class="row d-flex">
        <form class="coordinator">
          <b-form-select v-model="type" :options="types" class="form-control g-select"></b-form-select>
          <section class="">
            <b-button variant="primary" class="mr-3" @click="navigate" :disabled="!isgo">
              {{ translations.tcNext }}
            </b-button>
          </section>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import gBackground from '@/components/gBackground.vue'
import iPaperclip from '@/assets/svgs/iPaperclip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import { securityMixin } from '@/mixins/securityMixin'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'select-add-event',
  mixins: [translationMixin, securityMixin],
  data() {
    return {
      view_add_event_dropdown_controls: {
        calendar_add_event_state: 'fb4e7d33-9f68-4b68-9849-2a5c3fc84350',
      },
      types: [
        { text: '', value: null },
        { text: 'Camp', value: 'Camp' },
      ],
      type: null,
      isgo: false,
      translations: {},
      user_accessible: false,
    }
  },
  async created() {
    this.page_load()
  },
  watch: {
    type: function (newval, oldval) {
      this.isgo = !!newval
    },
  },
  methods: {
    ...mapActions({
      getControlPermissions: 'user/getControlPermissions',
      setLoadingStatus: 'menu/setLoadingStatus',
      clearSelected: 'eventCalendar/clearSelected',
    }),
    navigate() {
      if (!!this.type) {
        if (this.type === 'State') {
          this.$router.push({ path: '/calendar/state/event/geographic-dist' })
        } else {
          this.$router.push({ path: '/calendar/camp-calendar/add-event' })
        }
      }
    },
    async page_load() {
      let result = false
      await this.clearSelected()
      this.setLoadingStatus(true)
      await Promise.all([await this.getViewTranslations(), (result = this.loadTranslations())]).then((result) => {
        this.setLoadingStatus(false)
      })
    },
    loadTranslations() {
      this.types[0].text = this.translations.tcSelectEvent
      this.types[1].text = this.translations.tcCamp

      if (this.user_accessible) {
        if (this.officerToolbarSelected.camp_number.substring(0, 1) === 'U') {
          const txtState = this.translations.tcState || 'State'
          this.types.push({ text: txtState, value: 'State' })
        } else {
          const txtCountry = this.translations.tcCountry || 'Country'
          this.types.push({ text: txtCountry, value: 'State' })
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      officerToolbarSelected: 'user/officerToolbarSelected',
      prefCulture: 'user/userPreferredCulture',
      userLogin: 'user/userLogin',
    }),
  },
  async mounted() {
    await this.getControlPermissions(this.view_add_event_dropdown_controls.calendar_add_event_state).then(() => {
      this.user_accessible = this.determineAccessibility(this.view_add_event_dropdown_controls.calendar_add_event_state)
    })
  },
  components: {
    gBackground: gBackground,
    iPaperclip: iPaperclip,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';
// form template for add-template on AddCampAnnouncement.vue USE AddCampAnnouncement as include

.add-template {
  h1 {
    margin-bottom: 34px;
  }
  hr {
    margin: 60px 0 35px;
  }
  @include breakpoint(sm) {
    .page-header {
      text-align: center;
      h1 {
        font-size: 42px;
      }
    }
  }
  .button-group {
    .btn-primary {
      border: 1px solid #003946;
      text-transform: uppercase !important;
    }
  }
  .g-background {
    margin: 90px auto 104px;
  }
  label {
  }
  .form-section {
    font-size: 22px;
    font-weight: bold;
    display: block;
    line-height: 44px;
    letter-spacing: 0.73px;
    text-transform: uppercase;
    color: #636363;
    margin-bottom: 10px;
  }
  .form-block {
    .form-element {
      margin-bottom: 10px;

      &.form-attachment {
        margin-bottom: 60px;
        margin-top: 45px;
        @include breakpoint(sm) {
          margin-top: 25px;
        }
      }
    }
  }
  .form-control {
    height: auto;
    font-weight: normal;
    text-transform: none;
    line-height: 25px;
    padding: 10px 20px;
    border-color: #636363;
    color: #636363;
  }
  .custom-checkbox {
    padding-left: 34px;
    line-height: 24px;
    margin-bottom: 10px;
    label {
      font-size: 16px;
      &:before {
        top: 0;
        left: -34px;
        border-radius: 0;
        width: 24px;
        height: 24px;
        border-color: #636363;
      }
      &:after {
        top: 0;
        left: -34px;
        height: 24px;
        width: 24px;
      }
    }
    .custom-control-input:checked ~ .custom-control-label::after {
      background-color: #003946;
    }
  }
  @include breakpoint(sm) {
    .form-buttons {
      .btn {
        width: 100%;
        margin-bottom: 15px !important;
        &:last-of-type {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

// 1060
// 410
// ----
// 650

.item-1-load-enter-active {
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.add-state-announcement {
  .g-mb-1 {
    margin-bottom: 42px;
  }
  .g-mb-2 {
    margin-bottom: 60px;
    @include breakpoint(sm) {
      margin-bottom: 45px;
    }
  }
  .i-tooltip {
    vertical-align: super;
    svg path {
      fill: #000 !important;
    }
  }
  .view-1 {
  }
  .view-1 {
    @include breakpoint(sm) {
      .btn-group {
        width: 100%;
      }
    }
  }
  .view-2 {
    .custom-control-inline {
      display: flex;
      margin-left: 30px;
      margin-bottom: 0;
      align-items: center;
      @include breakpoint(sm) {
        margin-left: 0;
      }
    }
    @include breakpoint(sm) {
      .btn-group {
        flex-wrap: wrap;
        width: 100%;
        .btn {
          width: 50%;
          flex: 0 1 auto;
          padding: 12px 5px;
        }
      }
      .member-type {
        flex-wrap: wrap;
        .btn-group {
          margin-bottom: 30px;
          @include breakpoint(sm) {
            // margin-bottom: ;
          }
          .btn {
            width: 33%;
          }
        }
      }
    }
  }
  .view-3 {
    input::placeholder {
      text-transform: uppercase;
      color: #636363;
      font-weight: bold;
    }
  }
  .view-4 {
    .col {
      display: block;
    }
    .col-a {
      max-width: 60%;
      flex-basis: 60%;
      @include breakpoint(sm) {
        max-width: 100%;
        flex-basis: 100%;
        margin-bottom: 30px;
      }
    }
    .col-b {
      max-width: 40%;
      flex-basis: 40%;
      @include breakpoint(sm) {
        max-width: 100%;
        flex-basis: 100%;
      }
    }
    section {
      margin-bottom: 30px;
    }
    p {
      margin-bottom: 22px;
      font-size: 16px;
      line-height: 22px;
    }
    .form-attachment {
      margin-bottom: 10px;
    }
    .calendar {
      display: flex;
      align-items: center;
      svg {
        margin-right: 15px;
        path {
          fill: #636363 !important;
        }
      }
    }
  }
  .send-notifications {
    margin-bottom: 30px;
    margin-top: 20px;
  }
  .form-attachment {
    .attachment-list {
      margin-bottom: 20px;
      span {
        margin: 0 30px 0 10px;
        color: #1e68fb;
      }
      svg:last-of-type {
        width: 14px;
        height: 14px;
      }
      @include breakpoint(sm) {
        .btn {
          width: auto !important;
        }
      }
    }
  }
}
.page-navigation {
  display: flex;
  align-items: center;
  padding: 30px 80px 100px;
  @include breakpoint(sm) {
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 30px 0 30px;
  }
  .nav-item {
    position: relative;
    @include breakpoint(sm) {
      width: 100%;
    }
    .icon {
      svg {
        fill: #8c8c8c !important;
      }
    }
    &.active {
      .icon {
        background-color: $secondary-color;
        svg {
          fill: #000000 !important;
        }
      }
      .text {
        color: #000;
      }
    }
  }
  .sep {
    height: 9px;
    flex: 1 1 auto;
    background-color: #ccc;
    &.active {
      background-color: $secondary-color;
    }
    @include breakpoint(sm) {
      position: relative;
      height: 30px;
      width: 9px;
      flex: 0 0 auto;
      left: 27px;
      transform: translateX(-50%);
    }
  }
  .icon {
    position: relative;
    height: 54px;
    width: 54px;
    flex: 0 0 auto;
    background-color: #ccc;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text {
    display: block;
    position: absolute;
    width: 140px;
    top: 100%;
    left: 50%;
    margin-top: 13px;
    transform: translateX(-50%);
    color: #8c8c8c;
    font-family: $open-sans;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.6px;
    line-height: 22px;
    text-align: center;
    @include breakpoint(sm) {
      width: calc(100% - 70px);
      left: 70px;
      top: 50%;
      margin-top: 0;
      transform: translateY(-50%);
      text-align: left;
    }
  }
}
.checkbox-row {
  display: flex;
  flex-wrap: wrap;
  .checkbox-block {
    min-width: 275px;
    margin-right: 20px;
    padding: 24px 20px 35px 30px;
    // border: 1px solid #e9e9e9;
    border-radius: 6px;
    // box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    @include breakpoint(sm) {
      width: 100%;
      margin-bottom: 15px;
      margin-right: 0;
    }
  }
  .bg-block-h {
    display: flex;
    margin-bottom: 15px;
    h3 {
      flex: 1 1 auto;
      margin: 0;
      color: $gray-200;
      font-family: $open-sans;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: initial;
      line-height: 22px;
      text-transform: none;
    }
    .toggle {
      height: 25px;
      width: 25px;
      flex: 0 0 auto;
      border: 2px solid $gray-200;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &:before,
      &:after {
        content: '';
        display: block;
        width: 7px;
        height: 2px;
        border-radius: 2px;
        transform: rotate(45deg);
        transform-origin: bottom;
        background-color: $gray-200;
        right: -1px;
        position: relative;
      }
      &:after {
        left: -1px;
        right: auto;
        transform: rotate(-45deg);
      }
    }
  }
  label {
    font-size: 16px;
    line-height: 22px;
  }
}

.event-type-form,
.member-type-form {
  display: flex;
  @include breakpoint(sm) {
    flex-wrap: wrap;
  }
  .custom-select {
    margin-right: 20px;
    width: 200px;
    @include breakpoint(sm) {
      width: 100%;
      max-width: 100%;
      margin-right: 0;
    }
  }
  #event-input {
    width: 340px;
    @include breakpoint(sm) {
      width: 100%;
    }
  }
}
.member-type-form {
  margin-bottom: 40px;
}
.event-type-form {
  margin-bottom: 10px;
  @include breakpoint(sm) {
    .custom-select {
      margin-bottom: 10px;
    }
  }
}
.coordinator {
  display: flex;
  @include breakpoint(sm) {
    flex-wrap: wrap;
  }
  .form-control {
    margin-right: 20px !important;
    @include breakpoint(sm) {
      margin-right: 0 !important;
      margin-bottom: 10px;
    }
    &.custom-select {
      width: 300px;
      @include breakpoint(sm) {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

.form-block {
  margin-bottom: 55px;
}

.every-week-on {
  > div {
    display: flex;
    align-items: center;
    .every-input {
      width: 55px;
    }
    span {
      font-weight: bold;
      text-transform: uppercase;
      &:first-of-type {
        margin-right: 10px;
      }
      &:last-of-type {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
    .custom-select {
      width: 180px;
    }
  }
}
</style>
